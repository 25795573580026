const CoreView = require('backbone/core-view');
const template = require('./pay-notification.tpl');
const checkAndBuildOpts = require('builder/helpers/required-opts');
var moment = require('moment');
var ru = require('moment/locale/ru');
moment.locale('ru',ru);
const REQUIRED_OPTS = [
  'userModel',
  'upgradeUrl',
  'payDays',
  'planDate'
];

module.exports = CoreView.extend({
  initialize: function (options) {
    checkAndBuildOpts(options, REQUIRED_OPTS, this);

    this.userAccount = this._userModel.get('account_type');
  },

  render: function () {
    this.clearSubViews();

    this.$el.html(
      template({
        upgradeUrl: this._upgradeUrl,
        payEndsAt: this._payDays,
        planDate: moment(this._planDate).format("DD MMMM YYYY"),
      })
    );

    return this;
  }
});
