var template = require('./quote.tpl');

var QUOTES = [
  { quote: "Там, где нас ждут, мы всегда оказываемся точно в срок.", author: "Пауло Коэльо" },
  { quote: "Есть такие места, где нам обязательно нужно быть. Если и непостоянно, то хотя бы периодически туда возвращаться.", author: "Эльчин Сафарли" },
  { quote: "Я неестественно часто бываю счастлив в таких местах, где вроде бы и не должен быть счастлив.", author: "Януш Вишневский" },
  { quote: "Иногда заходишь туда, где никогда не был, но чувствуешь, что ты находишься именно там, где должен быть.", author: "Тед Мосби" },
  { quote: "Единственное время, которое есть у вас — сейчас; единственное место — здесь.", author: "Бхагван Шри Раджниш" },
  { quote: "Хорошо, когда есть куда пойти, когда всё плохо.", author: "Чарльз Буковски" },
  { quote: "В жизни свое место знаю, и оно не последнее, ибо никогда не становлюсь в ряд.", author: "Марина Ивановна Цветаева" },
  { quote: "Вот где я хочу жить до конца своей жизни. Теплое местечко без воспоминаний.", author: "Энди Дюфрейн" },
  { quote: "Если и есть на свете такая штука, как абсолютное счастье, то это ощущение, что ты — в правильном месте.", author: "Фэнни Флэгг" },
  { quote: "С какой стати посещать одно и то же место, когда в мире еще столько неизведанных уголков?!", author: "Марк Леви" },
  { quote: "Мы все по-прежнему ходим друг мимо друга. В чужих городах, на улице, на вокзалах, в аэропортах, парках, на пляжах и в книжных магазинах. И только иногда встречаемся в закутке моих мыслей.", author: "Януш Леон Вишневский" },
  { quote: "Всё равно куда? Всё равно куда... Ты знаешь хоть, где это?", author: "Джеймс Кейн" },
  { quote: "Место человека там, куда он пожелает пойти.", author: "Вернер фон Браун" },
  { quote: "Мир полон великолепных мест, посетить которые не хватит и тысячи жизней.", author: "Генри Миллер" },
  { quote: "Не имеет значения, где жить, важно — с кем.", author: "Чарли Чаплин" }
];

/**
 * Random quote
 */
module.exports = function () {
  var idx = Math.round(Math.random() * (QUOTES.length - 1));

  return template(QUOTES[idx]);
};
