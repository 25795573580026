var _ = require('underscore');

var ERRORS = {
  generic: {
    level: 'error',
    refresh: true
  },
  limit: {
    level: 'error',
    error: 'Timeout',
    message: 'Сервер слишком долго не отвечает из-за плохого подключения или временной ошибки. Пожалуйста, попробуйте еще раз в ближайшее время.',
    refresh: true
  },
  no_data_available: {
    level: 'alert',
    error: 'Нет доступных данных',
    message: 'Нет результатов для комбинации фильтров, примененных к вашим данным. Попробуйте настроить свои фильтры или увеличить и повернуть карту, чтобы настроить вид карты.',
    refresh: false
  },
  too_many_bins: {
    level: 'error',
    error: 'Вернулось слишком много корзин',
    message: 'Возвращено слишком много корзин. Попробуйте выбрать менее детализированную агрегацию или отфильтровать источник данных.',
    refresh: false
  },
  dataview: {
    level: 'error',
    refresh: false
  }
};

module.exports = function (error) {
  var type = error && error.type && _.has(ERRORS, error.type)
    ? error.type
    : 'generic';
  return _.extend({}, error, ERRORS[type]);
};
